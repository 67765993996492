import type { GetExecutionResponse } from '../../execution/execution.model';
import { StudioVisualizationConfig, StudioVisualizationType } from '../models';

const makeCounterConfig = <T extends GetExecutionResponse['data']>(
    _?: T,
): StudioVisualizationConfig<StudioVisualizationType.Counter> => {
    return {
        type: StudioVisualizationType.Counter,
        options: {
            groups: [],
        },
        features: { export: false },
        parameters: {},
    };
};

export { makeCounterConfig };
