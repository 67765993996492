import { tryJsonParse } from '../../../../utils';
import { StudioVisualizationConfig } from '../visualizations';

import { GetDashboardResponse, GetDashboardResponseRaw } from './dashboard.model';
import { LayoutConfig, LayoutItemType } from './dashboard-layout.model';

const getPatchVisualizationsFromLayout = (
    layoutConfig?: LayoutConfig,
): { id: string; isFeatured?: boolean }[] | undefined => {
    if (!layoutConfig) return undefined;

    const layoutVisualizations = layoutConfig?.lg
        ?.filter(item => item.data.type !== LayoutItemType.Markdown)
        ?.map(item => ({ id: item.data.id, isFeatured: item.data.isFeatured ?? false }));

    return layoutVisualizations?.length > 0 ? layoutVisualizations : undefined;
};

const parseDashboardVisualizationConfigs = (visualizations: GetDashboardResponseRaw['data']['visualizations']) => {
    return visualizations.map(viz => ({
        ...viz,
        config: tryJsonParse<StudioVisualizationConfig<any>>(viz.config).parsedJson,
    }));
};

const parseDashboardVisualizations = (
    visualizations: GetDashboardResponseRaw['data']['visualizations'],
): GetDashboardResponse['data']['visualizations'] => {
    if (!visualizations?.length) return visualizations as [];

    return parseDashboardVisualizationConfigs(visualizations);
};

export { getPatchVisualizationsFromLayout, parseDashboardVisualizationConfigs, parseDashboardVisualizations };
