import { StudioVisualizationCounterConfig } from './counter-config';
import { StudioVisualizationPieConfig } from './pie-config';
import { StudioVisualizationSankeyConfig } from './sankey-config';
import { StudioVisualizationTableConfig } from './table-config';
import { StudioVisualizationTimeseriesConfig } from './time-series-config';

enum StudioVisualizationType {
    Timeseries = 'timeseries',
    Pie = 'pie',
    Counter = 'counter',
    Table = 'table',
    Sankey = 'sankey',
}

interface StudioVisualizationParameterSettings {
    enabled: boolean;
    initialValue: string;
    excludes: string[];
}

type GetConfigOptionsByType<T extends StudioVisualizationType> = T extends StudioVisualizationType.Table
    ? StudioVisualizationTableConfig
    : T extends StudioVisualizationType.Counter
      ? StudioVisualizationCounterConfig
      : T extends StudioVisualizationType.Pie
        ? StudioVisualizationPieConfig
        : T extends StudioVisualizationType.Sankey
          ? StudioVisualizationSankeyConfig
          : StudioVisualizationTimeseriesConfig<any>;

type StudioVisualizationConfig<T extends StudioVisualizationType = StudioVisualizationType.Timeseries> = {
    type: StudioVisualizationType;
    options: GetConfigOptionsByType<T>;
    features?: { export?: boolean };
    parameters: Record<string, StudioVisualizationParameterSettings>;
};

export type { StudioVisualizationConfig };
export { StudioVisualizationType };
