import { AnyObj } from '../../../../typescript';
import { makeStringTemplate } from '../../../../utils';
import { defineEndpoints } from '../../../config/define-endpoints';
import { DataWithMeta, PaginatedRequestV1, WithSameOriginProxy } from '../../../config/type-helpers';

enum StudioExecutionState {
    Success = 'success',
    Pending = 'pending',
    Executing = 'executing',
    Failed = 'failed',
}

/** @todo remove 'date'|'number'|'string' once our types in the backend are completely migrated */
type StudioExecutionColumnTypes =
    | 'date'
    | 'number'
    | 'string'
    | 'double'
    | 'smallint'
    | 'integer'
    | 'bigint'
    | 'numeric'
    | 'real'
    | 'decimal'
    | 'double precision'
    | 'timestamp with time zone'
    | 'text';

type MappedStudioExecutionColumnTypes = 'number' | 'date' | 'string';
/**
 * A mapping of StudioExecutionColumnTypes to the primitive JS types
 **/
const STUDIO_EXECUTION_COL_TYPE_MAP: Record<StudioExecutionColumnTypes, MappedStudioExecutionColumnTypes> = {
    /** number types */
    smallint: 'number',
    integer: 'number',
    bigint: 'number',
    decimal: 'number',
    numeric: 'number',
    real: 'number',
    /** hotfix for "DOUBLE PRECISION" coming back as "double" */
    double: 'number',
    'double precision': 'number',
    number: 'number',

    /** date types */
    date: 'date',
    'timestamp with time zone': 'date',

    /** string types */
    text: 'string',
    string: 'string',
};

interface ExecutionIntervalMeta {
    column: string;
    groupBy: string[];
    unit: string;
    duration: number;
    basis: number;
    aggregateViews: {
        value: string;
        label: string;
    }[];
}

type ExecutionMetadata<T extends string = string> = {
    column_types?: string[];
    column_names?: string[];
    limit: number;
    page: number;
    /** Dynamic total number of rows in the execution - is dynamic based on the limit and filter */
    total: number;
    /** Static totalnumber of rows in the execution */
    rowCount: number;
    columnCount: number;
    columnKeys: T[];
    columnTypes: Record<T[number], StudioExecutionColumnTypes>;
    dimensions: Record<string, ExecutionIntervalMeta>;
};

type ExecutionShape<T extends string = string> = {
    id: string;
    error?: string;
    userId: string;
    queryId: string;
    executionId: string;
    state: StudioExecutionState;
    rows: AnyObj[];
    metadata: ExecutionMetadata<T>;
    executionEndedAt: string;
    executionStartedAt: string;
    expiresAt: string;
    isExecutionFinished: '1' | '0';
    updatedAt: string;
    createdAt: string;
    userEmail: string;
};

type GetExecutionRequest = PaginatedRequestV1<
    AnyObj,
    WithSameOriginProxy<{
        id: string;
        filter?: string;
    }>
>;

type GetExecutionResponse = DataWithMeta<ExecutionShape>;

type GetExecutionsRequest = {
    queryId: string;
    page?: number;
    limit?: number;
    state?: StudioExecutionState;
    resultLimit?: number;
    resultPage?: number;
};

type GetExecutionsResponse = DataWithMeta<ExecutionShape[]>;

type PostExecuteQueryRequest = {
    queryId: string;
    userId?: string;
};

type PostExecuteQueryResponse = DataWithMeta<{
    message: string;
    id: string;
    slug: string;
    state: StudioExecutionState;
}>;

const STUDIO_EXECUTION_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        EXECUTION: template('/api/v1/studio/execution/{id}'),
        EXECUTION_PROXY: template('/api/studio/execution/{id}'),
        EXECUTIONS: template('/api/v1/studio/executions'),
    },
    POST: {
        EXECUTION: template('/api/v1/studio/execution'),
    },
}));

const getExecutionRedisKey = makeStringTemplate('query-execution.{id}');

export type {
    ExecutionIntervalMeta,
    ExecutionMetadata,
    GetExecutionRequest,
    GetExecutionResponse,
    GetExecutionsRequest,
    GetExecutionsResponse,
    MappedStudioExecutionColumnTypes,
    PostExecuteQueryRequest,
    PostExecuteQueryResponse,
    StudioExecutionColumnTypes,
};

export { getExecutionRedisKey, STUDIO_EXECUTION_COL_TYPE_MAP, STUDIO_EXECUTION_ENDPOINTS, StudioExecutionState };
