import type { Control, FieldValues } from 'react-hook-form';
import { tagMutations } from '@studio/api/tags/mutations';
import { tagQueries } from '@studio/api/tags/queries';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';

import { useBoolean } from '@blockworks/platform/hooks';
import { FlexBox, Modal, MultiSelectProps, Text } from '@blockworks/ui';
import { Bit } from '@blockworks/ui/bit';
import { Form } from '@blockworks/ui/forms/form';
import { cn } from '@blockworks/ui/utils';

import { StudioButton } from '../button';

type CreateOrSelectTagsProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    helpText: string;
    selected?: string[];
    placeholder?: string;
    hideLabel?: boolean;
    size?: MultiSelectProps['size'];
    type?: 'dashboard' | 'visualization' | 'query';
};

const DEFAULT_COLOR = '#000000';

const createTagSchema = yup.object({
    name: yup.string().required('A name is required.'),
    color: yup
        .string()
        .required('A color is required.')
        .matches(/^#?[0-9a-fA-F]{6}$/, 'Must be a valid hex value.'),
});

export const CreateOrSelectTags = ({
    control: entityControl,
    selected,
    helpText,
    placeholder = 'No tags selected',
    hideLabel,
    size,
}: CreateOrSelectTagsProps<any>) => {
    const tagModal = useBoolean(false);
    const queryClient = useQueryClient();

    // Control for a new tag created within this component
    const {
        control: tagControl,
        handleSubmit,
        reset,
        setError,
    } = Form.use({
        resolver: Form.resolvers.yup(createTagSchema),
    });

    const { data: tagOptions } = tagQueries.getTags.use({
        variables: {},
        select: tagQueries.getTags.select.selectTagsAsOptions,
    });

    const createTag = tagMutations.createTag.use({
        onSuccess: () => {
            handleOnTagClose();
            queryClient.invalidateQueries({
                queryKey: tagQueries.getTags.use.getKey(),
            });
        },
        onError: error => {
            if (error.message.includes('Duplicate')) {
                setError('name', {
                    message: 'This tag already exists',
                });
            } else {
                setError('name', {
                    message: error.message,
                });
            }
        },
    });

    const handleCreateTagSubmit = handleSubmit(async ({ name, color }) => {
        color = color.replace('#', '');
        try {
            const res = await createTag.mutateAsync({
                name,
                color,
            });
            if (!res?.data?.id) {
                throw new Error('Tag was not created properly.');
            }
        } catch (error) {
            console.error(error);
        }
    });

    const handleOnTagClose = () => {
        reset({ name: '', color: DEFAULT_COLOR });
        tagModal.setFalse();
    };

    const helperTextSize = size === 'sm' ? 'xxs' : ('xs' as const);

    return (
        <>
            <Modal
                open={tagModal.value}
                onClose={handleOnTagClose}
                classNames={{
                    base: 'w-[90%] h-[425px] md:w-[500px]',
                }}
            >
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>New Tag</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="gap-4">
                        <FlexBox gap="sm">
                            <FlexBox col gap="xs" w="1/2">
                                <Form.Input label="Name" name="name" size="sm" control={tagControl} />
                                <Form.Input
                                    label="Color (hex)"
                                    name="color"
                                    defaultValue={DEFAULT_COLOR}
                                    size="sm"
                                    control={tagControl}
                                />
                            </FlexBox>
                            <Bit.div pl={4} pt={4}>
                                <Form.ColorPicker name="color" control={tagControl} hideErrorMessage />
                            </Bit.div>
                        </FlexBox>
                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Submit
                            size="2sm"
                            type="button"
                            borderRadius="md"
                            onClick={handleCreateTagSubmit}
                            loading={createTag.isPending}
                            loadingText="Creating Tag"
                        >
                            Create Tag
                        </Form.Submit>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <Form.MultiSelect
                label={hideLabel ? undefined : 'Tags'}
                name="tagIds"
                placeholder={placeholder}
                defaultValue={selected}
                size={size}
                helpText={
                    <FlexBox alignItems="center" gap="xs">
                        <Text size={helperTextSize} color="muted">
                            {helpText}
                        </Text>
                        <StudioButton
                            variant="ghost"
                            intent="primary"
                            size="xxs"
                            className={cn('px-1 -m-0.5')}
                            onClick={tagModal.setTrue}
                        >
                            Create a new tag
                        </StudioButton>
                    </FlexBox>
                }
                control={entityControl}
                options={tagOptions ?? []}
            />
        </>
    );
};
