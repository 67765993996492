type SankeyLinkConfig = {
    source: string;
    target: string;
    columnKey: string;
    valueFormat: string;
    rowIndex: number;
    sumRange: {
        from?: number | null;
        to?: number | null;
        enabled: boolean;
    };
    color: string;
};

type SankeyGroupConfig = {
    id: string;
    name: string;
    links: SankeyLinkConfig[];
};

enum SankeyColorStrategy {
    Neutral = 'Neutral',
    Source = 'Source',
    Target = 'Target',
}

type StudioVisualizationSankeyConfig = {
    nodeWidth: number;
    nodePadding: number;
    colorStrategy: SankeyColorStrategy;
    showSourceLabels: boolean;
    showTargetLabels: boolean;
    groups: SankeyGroupConfig[];
};

export type { SankeyGroupConfig, SankeyLinkConfig, StudioVisualizationSankeyConfig };
export { SankeyColorStrategy };
