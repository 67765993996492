import type { GetExecutionResponse } from '../../execution/execution.model';
import { StudioVisualizationConfig, StudioVisualizationType } from '../models';

const makePieConfig = <T extends GetExecutionResponse['data']>(
    _?: T,
): StudioVisualizationConfig<StudioVisualizationType.Pie> => {
    return {
        type: StudioVisualizationType.Pie,
        options: {
            isDonut: true,
            showPartitionLabels: true,
            legend: { position: 'none' },
            groups: [],
        },
        features: { export: false },
        parameters: {} as any,
    };
};

export { makePieConfig };
