import { createApi } from '@blockworks/platform/api';

import {
    ALGOLIA_ENDPOINTS,
    AlgoliaContext,
    GetAlgoliaEditorialPostRequest,
    GetAlgoliaEditorialPostResponse,
    GetAlgoliaResearchPostRequest,
    GetAlgoliaResearchPostResponse,
    GetAlgoliaStudioTableRequest,
    GetAlgoliaStudioTableResponse,
} from './client.model';

/**
 * Client-side API for algolia. Expects a NextJS API route to be used as a proxy.
 * The expected API rotue for the proxy is: `/api/algolia/[context]/search`
 *
 * @see `makeAlgoliaSearchHandler` to make the handler for the API proxy route
 */
const algoliaApi = createApi(build => ({
    get: {
        researchPosts: build<GetAlgoliaResearchPostResponse, GetAlgoliaResearchPostRequest>({
            cachePrefix: 'algolia-research-posts',
            baseUrl: 'same-origin',
            query: ({ query, limit = 10, page = 0, searchOptions = {} }) => ({
                uri: ALGOLIA_ENDPOINTS.GET.SEARCH({ context: AlgoliaContext.Research }),
                params: {
                    query,
                    limit,
                    page,
                    ...searchOptions,
                },
            }),
        }),
        studioQueryTables: build<GetAlgoliaStudioTableResponse, GetAlgoliaStudioTableRequest>({
            cachePrefix: 'algolia-studio-query-tables',
            baseUrl: 'same-origin',
            query: ({ query = '', limit = 10, page = 0, searchOptions = {} }) => {
                return {
                    uri: ALGOLIA_ENDPOINTS.GET.SEARCH({ context: AlgoliaContext.StudioQueryTables }),
                    params: {
                        query,
                        limit,
                        page,
                        ...searchOptions,
                    },
                };
            },
        }),
        editorialPosts: build<GetAlgoliaEditorialPostResponse, GetAlgoliaEditorialPostRequest>({
            cachePrefix: 'algolia-editorial-posts',
            baseUrl: 'same-origin',
            query: ({ query, limit = 10, page = 0, searchOptions = {} }) => ({
                uri: ALGOLIA_ENDPOINTS.GET.SEARCH({ context: AlgoliaContext.Editorial }),
                params: {
                    query,
                    limit,
                    page,
                    ...searchOptions,
                },
            }),
        }),
    },
}));

export { algoliaApi };
