import { AnyObj } from '../../../../../typescript';
import { defineEndpoints } from '../../../../config/define-endpoints';
import { DataWithMeta, OrSymbol, PaginatedRequestV1, WithSameOriginProxy } from '../../../../config/type-helpers';
import { GetExecutionResponse, StudioExecutionState } from '../../execution';

import { StudioVisualizationConfig, StudioVisualizationType } from './visualization-config';

enum StudioVisualizationFilterKey {
    Type = 'types',
    Author = 'userIds',
    Query = 'queryIds',
    Filter = 'filter',
    Order = 'order',
    Tags = 'tagIds',
}

type VisualizationShape<T extends StudioVisualizationType = any> = {
    id: string;
    userId: string;
    userName: string;
    userEmail: string;
    queryId: string;
    queryTitle: string;
    querySlug: string;
    title: string;
    subTitle: string;
    type: StudioVisualizationType;
    description?: string;
    /** Arbitrary JSON string */
    config: StudioVisualizationConfig<T>;
    updatedAt: number;
    createdAt: number;
    lastExecutionId: string;
    executionEndedAt: number;
    executionState: StudioExecutionState;
    tagIds?: string[];
    obscuredScreenshotUrl?: string;
};

type VizExpandKeys = 'tagIds'; // @dev: add here as necessary

type GetVisualizationRequest = WithSameOriginProxy<{
    id: string;
    expand?: VizExpandKeys;
}>;

type GetVisualizationResponseRaw = DataWithMeta<Omit<VisualizationShape, 'config'> & { config: string }>;

type GetVisualizationResponse<T extends StudioVisualizationType = any> = DataWithMeta<
    VisualizationShape<T> & {
        status?: number;
        message?: string;
    }
>;

type GetVisualizationsResponse<T extends StudioVisualizationType = any> = DataWithMeta<VisualizationShape<T>[]>;

type GetVisualizationsRequest = PaginatedRequestV1<
    Pick<VisualizationShape, 'updatedAt' | 'createdAt' | 'queryTitle' | 'title' | 'type'>,
    {
        queryIds?: OrSymbol<string[] | string>;
        userIds?: OrSymbol<string[] | string>;
        filter?: string;
        types?: OrSymbol<StudioVisualizationType[] | StudioVisualizationType>;
        tagIds?: OrSymbol<string[] | string>;
    }
>;

type PostCreateVisualizationRequest = {
    title: string;
    type: StudioVisualizationType;
    tagIds?: string[];
    subTitle?: string;
    queryId: string;
    userId?: string;
} & (
    | {
          /** Provide a config if you want to override the default initialized config */
          config?: never;
          /** Provide an execution if you want to use the default initialized config */
          lastSuccessfulExecution: GetExecutionResponse['data'];
      }
    | {
          config: AnyObj;
          lastSuccessfulExecution?: never;
      }
);

type PostCreateVisualizationResponse = DataWithMeta<{
    message: string;
    id: string;
    slug: string;
}>;

type PatchUpdateVisualizationRequest = {
    id: string;
    title?: string;
    subTitle?: string;
    description?: string;
    config?: AnyObj;
    tagIds?: string[];
};

type DeleteVisualizationResponse = DataWithMeta<{ response: string; status: boolean }>;
type DeleteVisualizationRequest = { id: string };

type PostUpdateScreenshotRequest = {
    id: string;
    file: File;
};

type PostUpdateScreenshotResponse = DataWithMeta<{
    message: string;
    url: string;
}>;

const STUDIO_VISUALIZATIONS_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        VISUALIZATION: template('/api/v1/studio/visualization/{id}'),
        VISUALIZATION_PROXY: template('/api/studio/visualization/{id}'),
        VISUALIZATIONS: template('/api/v1/studio/visualizations'),
        VISUALIZATIONS_BY_QUERY: template('/api/v1/studio/visualizations/{queryId}'),
    },
    POST: {
        VISUALIZATION: template('/api/v1/studio/visualization'),
        UPDATE_SCREENSHOT: template('/api/v1/studio/visualization/{id}/screenshot'),
    },
    PATCH: {
        VISUALIZATION: template('/api/v1/studio/visualization/{id}'),
    },
    DELETE: {
        VISUALIZATION: template('/api/v1/studio/visualization/{id}'),
    },
}));

export type {
    DeleteVisualizationRequest,
    DeleteVisualizationResponse,
    GetVisualizationRequest,
    GetVisualizationResponse,
    GetVisualizationResponseRaw,
    GetVisualizationsRequest,
    GetVisualizationsResponse,
    PatchUpdateVisualizationRequest,
    PostCreateVisualizationRequest,
    PostCreateVisualizationResponse,
    PostUpdateScreenshotRequest,
    PostUpdateScreenshotResponse,
};

export { STUDIO_VISUALIZATIONS_ENDPOINTS, StudioVisualizationFilterKey };
