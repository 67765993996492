import type { GetExecutionResponse } from '../../execution/execution.model';
import { SankeyColorStrategy, StudioVisualizationConfig, StudioVisualizationType } from '../models';

const makeSankeyConfig = <T extends GetExecutionResponse['data']>(
    _?: T,
): StudioVisualizationConfig<StudioVisualizationType.Sankey> => {
    return {
        type: StudioVisualizationType.Sankey,
        options: {
            nodeWidth: 10,
            nodePadding: 10,
            colorStrategy: SankeyColorStrategy.Target,
            showSourceLabels: true,
            showTargetLabels: true,
            groups: [],
        },
        features: { export: false },
        parameters: {},
    };
};

export { makeSankeyConfig };
