import type { GetExecutionResponse } from '../../execution/execution.model';
import { StudioVisualizationConfig, StudioVisualizationType } from '../models';

const makeTableConfig = <T extends GetExecutionResponse['data']>(
    _?: T,
): StudioVisualizationConfig<StudioVisualizationType.Table> => {
    return {
        type: StudioVisualizationType.Table,
        options: {
            enableSearch: true,
            enableColumnVisibility: false,
            enableResizableColumns: false,
            groups: [],
        },
        features: { export: true },
        parameters: {},
    };
};

export { makeTableConfig };
